import { AccountSettings } from "@aws-amplify/ui-react";
import { Form, Formik } from "formik";
import { Button, Form as BsForm } from "react-bootstrap";
import * as Yup from "yup";

import { FormItem } from "../../../common/components/form-item";
import { PageTitle } from "../../../common/components/page-title";
import { createToast, ToastType } from "../../../common/components/toasts";

const schema = Yup.object({
  name: Yup.string().required(),
});

interface SettingsProps {
  name: string;
  onAttributeChange: (attribute: { [key: string]: string }) => void;
  isSubmitting: boolean;
  addToast: (toast: ToastType) => void;
  isCognitoUser: boolean;
}

export const Settings = ({
  name,
  onAttributeChange,
  isSubmitting,
  addToast,
  isCognitoUser,
}: SettingsProps) => {
  return (
    <>
      <PageTitle>Settings</PageTitle>
      <div className="container pt-3 easi-sign-in">
        <section className="pb-5 mb-5">
          <h2 className="mb-5">Update name</h2>
          <Formik
            validationSchema={schema}
            onSubmit={onAttributeChange}
            initialValues={{ name }}
          >
            <Form method="post" noValidate>
              <fieldset disabled={isSubmitting}>
                <FormItem
                  className="mb-3"
                  name="name"
                  label="Name"
                  type="text"
                  placeholder="Your name"
                  as={BsForm.Control}
                />
                <Button variant="primary" className="w-100" type="submit">
                  Update name
                </Button>
              </fieldset>
            </Form>
          </Formik>
        </section>
        {isCognitoUser && (
          <section className="pb-5 mb-5">
            <h2 className="mb-5">Update password</h2>
            <AccountSettings.ChangePassword
              onSuccess={() =>
                addToast(
                  createToast({
                    header: "Success",
                    body: "Password has been updated",
                  })
                )
              }
              onError={() =>
                addToast(
                  createToast({
                    header: "Error",
                    body: "Failed to update password",
                    type: "danger",
                  })
                )
              }
            />
          </section>
        )}
        {/* <fieldset disabled>
        <section className="pb-5 mb-5">
          <h2 className="mb-5">Delete account</h2>
          <AccountSettings.DeleteUser onSuccess={handleSuccess} />
        </section>
        </fieldset> */}
      </div>
    </>
  );
};
