import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { getSortedRowModel } from "@tanstack/react-table";
import { getFilteredRowModel } from "@tanstack/react-table";
import { useReactTable } from "@tanstack/react-table";
import _ from "lodash";
import { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { UsersWorkspace } from "../../../api";
import paths from "../../../paths";
import { ConfirmerModal } from "../confirmer-modal";
import { CustomDropdownToggleButton } from "../custom-dropdown-toggle-button";
import { Table } from "../table";

const columnHelper = createColumnHelper<UsersWorkspace>();

interface UserWorkspacesListProps {
  userWorkspaces: UsersWorkspace[];
  onEdit: (user: UsersWorkspace) => void;
  onDelete: (user: UsersWorkspace) => void;
  isSubmitting: boolean;
}

export const UserWorkspacesList = ({
  userWorkspaces,
  onEdit,
  onDelete,
  isSubmitting,
}: UserWorkspacesListProps) => {
  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<UsersWorkspace, any>[] = [
      columnHelper.accessor("workspace.label", {
        header: () => "Label",
        cell: (info) => (
          <Link
            to={_.replace(
              paths.DASHBOARD_ADMIN_WORKSPACE,
              ":workspaceId",
              info.row.original.workspace.workspaceId
            )}
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("workspace.tenancyName", {
        header: () => "Tenancy name",
      }),
      columnHelper.accessor("role", {
        header: () => "Role",
        meta: { filterVariant: "select" },
      }),
      columnHelper.display({
        id: "actions",
        header: () => <div className="text-end w-100">Actions</div>,
        cell: ({ row: { original: userWorkspace } }) => (
          <div className="text-end">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={CustomDropdownToggleButton}
                className="btn btn-outline-dark px-3"
                title="Perform actions"
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => onEdit(userWorkspace)}
                >
                  Edit
                </Dropdown.Item>
                <ConfirmerModal
                  title="Remove workspace"
                  body="Are you sure you want to remove workspace?"
                  isSubmitting={isSubmitting}
                  onConfirm={() => onDelete(userWorkspace)}
                >
                  {({ open }) => (
                    <Dropdown.Item onClick={open}>Delete</Dropdown.Item>
                  )}
                </ConfirmerModal>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }),
    ];
    return columns;
  }, [isSubmitting, onDelete, onEdit]);
  const table = useReactTable({
    data: userWorkspaces,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return <Table table={table} />;
};
