import { useNavigation, useSubmit } from "react-router-dom";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { useActionToasts } from "../../../common/hooks";

export const useUsers = () => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  useActionToasts();
  const { state } = useNavigation();
  const onSubmit = useSubmit();
  const isSubmitting = state === "submitting";
  return { isSubmitting, onSubmit };
};
