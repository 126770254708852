import { User } from "../../../api";

export const defaultUser: User = {
  enabled: undefined,
  groups: [],
  name: "",
  projects: [],
  userId: "",
  username: "",
};
