import _ from "lodash";
import { DateTime } from "luxon";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import {
  Activity,
  SubscriptionPayment,
  SubscriptionPayments,
  Workspaces,
} from "../../../api";
import { CONFIG } from "../../../config";
import { TIMEZONE_API } from "../../constants";
import { ActivityData, DatetimeRange, Workspace } from "../../types";

export interface ActivityLoaderData {
  activity: ActivityData[];
  datetimeRange: DatetimeRange;
  workspaces: Workspace[];
  subscriptionPayments: SubscriptionPayment[];
}

export const activityFetcher = async (
  datetimeRange: DatetimeRange,
  signal: AbortSignal
): Promise<ActivityLoaderData> => {
  const [workspaces, activityResponse, subscriptionPayments] =
    await Promise.all([
      Workspaces.fetchAndParse({ signal }),
      Activity.fetch({ datetimeRange, signal }),
      CONFIG.IS_BILLING_ENABLED
        ? SubscriptionPayments.fetchAndParse({ signal })
        : new Promise<SubscriptionPayment[]>((resolve) => resolve([])),
    ]);
  const activity = Activity.parseFromApi(activityResponse, workspaces);
  return {
    activity,
    datetimeRange,
    workspaces,
    subscriptionPayments,
  };
};

const now = DateTime.now().setZone(TIMEZONE_API);

const initialDatetimeRange: DatetimeRange = [
  now.minus({ months: 1 }).plus({ days: 1 }).startOf("day").toISO() as string,
  now.endOf("day").toISO() as string,
];

export const activityLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const start = url.searchParams.get("start");
  const end = url.searchParams.get("end");
  let datetimeRange = initialDatetimeRange;
  if (_.isString(start) || _.isString(end)) {
    datetimeRange = [start, end] as DatetimeRange;
  }
  return defer({ activity: activityFetcher(datetimeRange, request.signal) });
};
