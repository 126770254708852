import _ from "lodash";

import { fetcher } from "../auth";
import { apiUrl } from "./utils";

export interface SubscriptionPayment {
  start: string;
  end: string;
  amount: number;
  workspaceId: string;
}

interface ApiSubscriptionPayment {
  start: string;
  end?: string;
  workspace_id: string;
  amount: number;
  status: string;
}
interface RequestArguments {
  signal: AbortSignal;
}

export const SubscriptionPayments = {
  async fetch({ signal }: RequestArguments) {
    const { data } = await fetcher({
      url: apiUrl("payments/subscriptions/payments"),
      signal,
    });
    return data;
  },
  parseFromApi(data: ApiSubscriptionPayment[]): SubscriptionPayment[] {
    return _.map(
      _.filter(data, ({ status, end }) => _.isString(end) && status === "paid"),
      ({ workspace_id, end, amount, ...rest }) => ({
        ...rest,
        amount: amount / 100, // Convert cents into dollars.
        end: end as string,
        workspaceId: workspace_id,
      })
    );
  },
  async fetchAndParse(args: RequestArguments): Promise<SubscriptionPayment[]> {
    const data = await SubscriptionPayments.fetch(args);
    return SubscriptionPayments.parseFromApi(data);
  },
};
