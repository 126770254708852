import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import clsx from "clsx";
import _ from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { WorkspaceWithUsers } from "../../../api";
import paths from "../../../paths";
import { plans } from "../../../plans";
import { TabComponentMode } from "../../types";
import { Table } from "../table";

type WorkspacesListWorkspace = Pick<
  WorkspaceWithUsers,
  "label" | "tenancyName" | "users" | "planId" | "status" | "workspaceId"
>;

const columnHelper = createColumnHelper<WorkspacesListWorkspace>();

interface WorkspacesListProps {
  workspaces: WorkspacesListWorkspace[];
  isSubmitting: boolean;
  authedUserId: string;
  mode?: TabComponentMode;
}

const settingsByMode = {
  [TabComponentMode.User]: {
    workspacePath: paths.DASHBOARD_WORKSPACE,
  },
  [TabComponentMode.Admin]: {
    workspacePath: paths.DASHBOARD_ADMIN_WORKSPACE,
  },
};

export const WorkspacesList = ({
  workspaces,
  mode = TabComponentMode.User,
}: WorkspacesListProps) => {
  const columns = useMemo(() => {
    const settings = settingsByMode[mode];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<WorkspacesListWorkspace, any>[] = [
      columnHelper.accessor("label", {
        header: () => "Label",
        cell: (info) => (
          <Link
            className="stretched-link"
            to={_.replace(
              settings.workspacePath,
              ":workspaceId",
              info.row.original.workspaceId
            )}
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("tenancyName", {
        header: () => "Tenancy name",
      }),
      columnHelper.accessor((row) => row.users.length, {
        id: "users",
        header: () => "Users",
        filterFn: (row, columnId, filterValue) =>
          row.getValue(columnId) === _.toNumber(filterValue),
      }),
      columnHelper.accessor(
        (row) => _.get(plans[row.planId], "label", row.planId),
        {
          id: "plan",
          header: () => "Plan",
          meta: { filterVariant: "select" },
        }
      ),
      columnHelper.accessor("status", {
        id: "status",
        header: () => "Status",
        cell: (info) => (
          <>
            <FontAwesomeIcon
              className={clsx("me-2", {
                "text-danger": info.getValue() === "Disabled",
                "text-success": info.getValue() === "Enabled",
              })}
              icon={faCircle}
            />
            {info.getValue()}
          </>
        ),
        meta: { filterVariant: "select" },
      }),
    ];
    return columns;
  }, [mode]);
  const table = useReactTable({
    data: workspaces,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return <Table table={table} />;
};
