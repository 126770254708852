import _ from "lodash";
import { Button, Card, Col, Row } from "react-bootstrap";

import { CONFIG } from "../../../config";

const baseDomain = CONFIG.BASE_DOMAIN;

const apps = [
  {
    name: "Jupyterhub",
    image: "/jupyter-logo.svg",
    text: "Python environment for interactive coding, data analysis, and visualization",
    link: `https://hub.${baseDomain}/`,
  },
  {
    name: "Argo Workflows",
    image: "/argocd-logo.svg",
    text: "Container-native workflow engine for orchestrating parallel jobs on Kubernetes",
    link: `https://argo.${baseDomain}/`,
  },
  {
    name: "Terria",
    image: "/terria-logo.png",
    text: "An open-source framework for web-based geospatial catalogue explorers",
    link: `https://map.${baseDomain}/`,
  },
  {
    name: "Github",
    image: "/github-logo.svg",
    text: "Access to EASI's GitHub repositories",
    link: "https://github.com/csiro-easi",
  },
  {
    name: "Docs",
    image: "/readthedocs-logo.svg",
    text: "Find everything you need to know, from getting started to advanced tips",
    link: `https://docs.${baseDomain}/`,
  },
];

export const AppList = () => {
  return (
    <Row>
      {_.map(apps, (app) => (
        <Col sm="6" lg="4" xl="3" key={app.name} className="mb-4">
          <Card className="h-100">
            <div className="ratio ratio-1x1">
              <Card.Img
                className="p-4"
                variant="top"
                src={app.image}
                alt={`${app.name} logo`}
              />
            </div>
            <Card.Body className="d-flex flex-column h-100 justify-content-between">
              <div className="mb-3">
                <Card.Title>{app.name}</Card.Title>
                <Card.Text>{app.text}</Card.Text>
              </div>
              <Button
                as="a"
                href={app.link}
                className="w-100"
                variant="primary"
                target="_blank"
              >
                Open {app.name}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
