import { faCircle, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { getSortedRowModel } from "@tanstack/react-table";
import { getFilteredRowModel } from "@tanstack/react-table";
import { useReactTable } from "@tanstack/react-table";
import clsx from "clsx";
import _ from "lodash";
import { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { WorkspaceUserWithUserFields } from "../../../api";
import paths from "../../../paths";
import { TabComponentMode } from "../../types";
import { ConfirmerModal } from "../confirmer-modal";
import { CustomDropdownToggleButton } from "../custom-dropdown-toggle-button";
import { Table } from "../table";

const columnHelper = createColumnHelper<WorkspaceUserWithUserFields>();

interface WorkspaceUsersListProps {
  workspaceUsers: WorkspaceUserWithUserFields[];
  onEdit: (user: WorkspaceUserWithUserFields) => void;
  onDelete: (user: WorkspaceUserWithUserFields) => void;
  isSubmitting: boolean;
  authedUserId?: string;
  isAdmin: boolean;
  mode?: TabComponentMode;
}

const settingsByMode = {
  [TabComponentMode.User]: {
    hasActionButtons: false,
    includeLinkToUser: false,
  },
  [TabComponentMode.Admin]: {
    hasActionButtons: true,
    includeLinkToUser: true,
  },
};

export const WorkspaceUsersList = ({
  workspaceUsers,
  onEdit,
  onDelete,
  isSubmitting,
  authedUserId,
  isAdmin,
  mode = TabComponentMode.User,
}: WorkspaceUsersListProps) => {
  const settings = settingsByMode[mode];

  const hasAction = !_.isEmpty(
    _.reject(workspaceUsers, { userId: authedUserId })
  );
  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<WorkspaceUserWithUserFields, any>[] = [
      columnHelper.accessor("userId", {
        header: () => "ID",
        cell: (info) =>
          settings.includeLinkToUser ? (
            <Link
              to={_.replace(
                paths.DASHBOARD_ADMIN_USER,
                ":userId",
                info.row.original.userId
              )}
            >
              {info.getValue()}
            </Link>
          ) : (
            info.getValue()
          ),
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
      }),
      columnHelper.accessor("role", {
        header: () => "Role",
        meta: { filterVariant: "select" },
      }),
      columnHelper.accessor(
        (row) => {
          if (row.enabled === true) {
            return "Enabled";
          }
          if (row.enabled === false) {
            return "Disabled";
          }
          return "Invited";
        },
        {
          id: "enabled",
          header: () => "Status",
          cell: (info) => (
            <>
              <FontAwesomeIcon
                className={clsx("me-2", {
                  "text-danger": info.getValue() === "Disabled",
                  "text-success": info.getValue() === "Enabled",
                  "text-warning": info.getValue() === "Invited",
                })}
                icon={faCircle}
              />
              {info.getValue()}
            </>
          ),
          meta: { filterVariant: "select" },
        }
      ),
    ];
    if (hasAction && (settings.hasActionButtons || isAdmin)) {
      columns.push(
        columnHelper.display({
          id: "actions",
          header: () => <div className="text-end w-100">Actions</div>,
          cell: ({ row: { original: workspaceUser } }) => (
            <div className="text-end">
              {(settings.hasActionButtons ||
                workspaceUser.userId !== authedUserId) && (
                <Dropdown align="end">
                  <Dropdown.Toggle
                    as={CustomDropdownToggleButton}
                    className="btn btn-outline-dark px-3"
                    title="Perform actions on user"
                    disabled={isSubmitting}
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => onEdit(workspaceUser)}
                    >
                      Edit
                    </Dropdown.Item>
                    {workspaceUser.userId !== authedUserId && (
                      <ConfirmerModal
                        title="Remove user"
                        body="Are you sure you want to remove user?"
                        isSubmitting={isSubmitting}
                        onConfirm={() => onDelete(workspaceUser)}
                      >
                        {({ open }) => (
                          <Dropdown.Item onClick={open}>Delete</Dropdown.Item>
                        )}
                      </ConfirmerModal>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          ),
        })
      );
    }
    return columns;
  }, [
    hasAction,
    authedUserId,
    isAdmin,
    isSubmitting,
    onDelete,
    onEdit,
    settings,
  ]);
  const table = useReactTable({
    data: workspaceUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return <Table table={table} />;
};
