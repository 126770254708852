import "./nav.scss";

import _ from "lodash";
import { Nav as RbNav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import paths from "../../../paths";

interface NavProps {
  isAuthed: boolean;
  signOut?: () => void;
  userId?: string;
  onSelect?: () => void;
}

/**
 * Primary navigation UI component.
 */
export const Nav = ({
  isAuthed,
  userId,
  signOut = _.noop,
  onSelect = _.noop,
}: NavProps) => {
  const items: {
    to?: string;
    href?: string;
    label: string;
    onClick?: () => void;
  }[] = [
    {
      href: "https://research.csiro.au/easi/about/",
      label: "About",
    },
    {
      href: "https://research.csiro.au/easi/features/",
      label: "Features",
    },
    {
      href: "https://research.csiro.au/easi/category/case-studies/",
      label: "Case studies",
    },
    // {
    //   href: "https://research.csiro.au/easi/category/pricing/",
    //   label: "Pricing",
    // },
    // {
    //   to: paths.RESOURCES,
    //   label: "Resources",
    // },
    {
      href: "https://research.csiro.au/easi/support/",
      label: "Support",
    },
  ];
  if (!isAuthed) {
    items.push({
      to: paths.SIGN_IN,
      label: "Sign in",
    });
  }
  return (
    <RbNav className="small flex-column flex-lg-row">
      {_.map(items, ({ label, to, href, onClick = _.noop }) => (
        <RbNav.Item key={label} className="d-flex align-items-center">
          <RbNav.Link
            className="easi-nav__link"
            as={_.isString(to) ? NavLink : undefined}
            onClick={() => {
              onClick();
              onSelect();
            }}
            {...(_.isString(to) ? ({ to } as any) : {})} // eslint-disable-line
            href={href}
          >
            {label}
          </RbNav.Link>
        </RbNav.Item>
      ))}
      {isAuthed && (
        <NavDropdown title="Account">
          <li>
            <h6 className="dropdown-header">{userId}</h6>
          </li>
          <NavDropdown.Divider />
          <NavDropdown.Item as={NavLink} to={paths.DASHBOARD}>
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to={paths.SETTINGS}>
            Settings
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
        </NavDropdown>
      )}
    </RbNav>
  );
};
