import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { User } from "../../../api";
import { Spinner } from "../../../common/components/spinner";
import { useUsers } from "./use-users";
import { Users } from "./users.component";

export interface UsersLoader {
  users: Promise<User[]>;
}

export const ConnectedUsers = () => {
  const data = useLoaderData() as UsersLoader;
  const { isSubmitting, onSubmit } = useUsers();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.users}>
        {(users: User[]) => (
          <Users
            users={users}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
