import "./header.scss";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";

import { HeaderTitle } from "../header-title";
import { Logo } from "../logo";
import { Nav } from "../nav";

export const Header = () => {
  const [show, setShow] = useState(false);
  return (
    <header className="py-2 shadow-sm">
      <div className="container-lg py-1 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Logo />
          <a
            href="https://research.csiro.au/easi/"
            className="text-decoration-none"
          >
            <HeaderTitle />
          </a>
        </div>
        <FontAwesomeIcon
          icon={faBars}
          className="d-lg-none align-self-start"
          onClick={() => setShow(true)}
          size="lg"
        />
        <Offcanvas
          show={show}
          onHide={() => setShow(false)}
          responsive="lg"
          placement="end"
          className="flex-grow-1"
        >
          <Offcanvas.Header closeButton className="align-items-start">
            <Offcanvas.Title>
              Earth Analytics Science &amp; <br />
              Innovation Platform
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="text-end ms-3 flex-grow-1">
              <a
                href="https://www.csiro.au"
                className="text-dark text-decoration-none easi-header__csiro-link ff-alt"
                target="_blank"
                rel="noreferrer"
              >
                CSIRO.AU
              </a>
              <hr className="mb-1" />
              <div className="d-flex align-items-center justify-content-end pb-4 mb-2">
                <Nav onSelect={() => setShow(false)} />
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
};
