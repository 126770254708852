import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { SubscriptionPayment } from "../../../api";
import { ReportPeriodId, reportPeriods, Scopes } from "../../report-periods";
import { ActivityData } from "../../types";
import { activityFetcher } from "../activity/activity-loader";

export interface ReportLoaderData {
  activity: ActivityData[];
  scopes: Scopes;
  title: string;
  subscriptionPayments: SubscriptionPayment[];
}

export const reportFetcher = async (
  periodId: ReportPeriodId,
  start: string,
  signal: AbortSignal
): Promise<ReportLoaderData> => {
  const period = reportPeriods[periodId];
  const scopes = period.getScopes(start);
  const datetimeRange = scopes.outer.datetimeRange;
  const { activity, subscriptionPayments } = await activityFetcher(
    datetimeRange,
    signal
  );
  const title = `${period.label} costs report`;
  return {
    activity,
    scopes,
    title,
    subscriptionPayments,
  };
};

export const reportLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const start = url.searchParams.get("start");
  const periodId = url.searchParams.get("period-id") as ReportPeriodId;
  if (_.isNull(start) || _.isNull(periodId)) {
    throw new Error("No start and or period-id provided in query string");
  }
  return defer({ report: reportFetcher(periodId, start, request.signal) });
};
