import { isRouteErrorResponse } from "react-router-dom";

import { RequireAuth } from "../../../auth";
import { NOT_LOGGED_IN_ERROR } from "../../../auth/constants";
import { PageTitle } from "../page-title";

interface ErrorBoundaryProps {
  error: unknown;
}
export const ErrorBoundary = ({ error }: ErrorBoundaryProps) => {
  if (error === NOT_LOGGED_IN_ERROR) {
    return <RequireAuth />;
  }
  console.error(error);
  let content = "Something went wrong";

  if (isRouteErrorResponse(error) || error instanceof Response) {
    switch (error.status) {
      case 403:
        content = error.statusText;
        break;

      case 404:
        content = "This page doesn't exist!";
        break;

      default:
        content = "Something went wrong";
        break;
    }
  }

  return (
    <div className="container">
      <PageTitle>{content}</PageTitle>
    </div>
  );
};
