import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";
import { SubmitTarget } from "react-router-dom/dist/dom";

import { SpawnerGroup } from "../../../api";
import { SpawnerGroupModal } from "../spawner-group-modal";
import { SpawnerGroupsList } from "../spawner-groups-list";

interface ModalState {
  show: boolean;
  title?: string;
  spawnerGroup?: SpawnerGroup;
  method?: "post" | "patch";
}

interface SpawnerGroupsProps {
  spawnerGroups: SpawnerGroup[];
  resourceOptionIds: string[];
  isSubmitting: boolean;
  onSubmit: SubmitFunction;
  error?: string;
}

export const SpawnerGroups = ({
  onSubmit,
  error,
  isSubmitting,
  spawnerGroups,
  resourceOptionIds,
}: SpawnerGroupsProps) => {
  const [modal, setModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setModal({ show: false });
    }
  }, [isSubmitting]);
  if (_.isString(error)) {
    return <Alert variant="danger">{error}</Alert>;
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-5">
        <Button
          variant="primary"
          onClick={() =>
            setModal({ show: true, title: "Add spawner group", method: "post" })
          }
        >
          Add spawner group
        </Button>
      </div>
      {spawnerGroups.length === 0 ? (
        <Alert variant="info">No spawnerGroups</Alert>
      ) : (
        <SpawnerGroupsList
          isSubmitting={isSubmitting}
          spawnerGroups={spawnerGroups}
          onEdit={(spawnerGroup) =>
            setModal({
              show: true,
              title: "Edit spawner group",
              spawnerGroup,
              method: "patch",
            })
          }
          onDelete={(spawnerGroup) =>
            onSubmit(
              {
                spawnerGroupId: spawnerGroup.spawnerGroupId,
              },
              {
                method: "delete",
                encType: "application/json",
              }
            )
          }
        />
      )}
      <SpawnerGroupModal
        onHide={() => setModal({ show: false })}
        show={modal.show}
        title={modal.title}
        isSubmitting={isSubmitting}
        isSpawnerGroupIdReadOnly={modal.method === "patch"} // Can't edit spawner group ID once it's created.
        onSubmit={(values) => {
          if (_.isEqual(values, modal.spawnerGroup)) {
            setModal({ show: false });
          } else {
            onSubmit(values as unknown as SubmitTarget, {
              method: modal.method,
              encType: "application/json",
            });
          }
        }}
        spawnerGroup={modal.spawnerGroup}
        resourceOptionIds={resourceOptionIds}
      />
    </>
  );
};
