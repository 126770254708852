import _ from "lodash";

import { PlainTextTableData } from "../types";

export const generateCsvUrl = ({ headings, rows }: PlainTextTableData) => {
  const data = _.zip(headings, ...rows);
  const csv = _.join(
    _.map(data, (row) =>
      _.join(
        _.map(row, (value) => `"${_.replace(_.toString(value), '"', '""')}"`),
        ","
      )
    ),
    "\n"
  );
  const csvData = new Blob([csv], { type: "text/csv" });
  return URL.createObjectURL(csvData);
};
