import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { Link, SubmitFunction } from "react-router-dom";

import { UsersWorkspace, UserWithWorkspaces, Workspace } from "../../../api";
import paths from "../../../paths";
import { ConfirmerModal } from "../confirmer-modal";
import { UserForm } from "../user-form";
import { UserWorkspaceModal } from "../user-workspace-modal";
import { UserWorkspacesList } from "../user-workspaces-list";

interface ModalState {
  show: boolean;
  title?: string;
  workspace?: UsersWorkspace;
  method?: "post" | "patch";
}

interface UserProps {
  user: UserWithWorkspaces;
  workspaces: Workspace[];
  onSubmit: SubmitFunction;
  isSubmitting: boolean;
  authedUserId: string;
}

export const User = ({
  user,
  workspaces,
  authedUserId,
  onSubmit,
  isSubmitting,
}: UserProps) => {
  const [modal, setModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setModal({ show: false });
    }
  }, [isSubmitting]);
  const status =
    user.enabled === true
      ? "Enabled"
      : user.enabled === false
        ? "Disabled"
        : "Invited";
  const workspacesUserIsNotIn = _.reject(workspaces, ({ workspaceId }) =>
    _.includes(_.map(user.workspaces, "workspace.workspaceId"), workspaceId)
  );
  return (
    <>
      <nav aria-label="breadcrumb" className="mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={paths.DASHBOARD_ADMIN_USERS}>Users</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {user.userId}
          </li>
        </ol>
      </nav>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <FontAwesomeIcon
            className={clsx("me-2", {
              "text-danger": status === "Disabled",
              "text-success": status === "Enabled",
              "text-warning": status === "Invited",
            })}
            icon={faCircle}
          />
          {status}
        </div>
        <div>
          {user.userId !== authedUserId && (
            <Stack gap={3} direction="horizontal">
              {user.enabled === false && (
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    onSubmit(
                      { userId: user.userId, enabled: true },
                      {
                        method: "patch",
                        encType: "application/json",
                      }
                    )
                  }
                >
                  Enable
                </Button>
              )}
              {user.enabled === true && (
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    onSubmit(
                      { userId: user.userId, enabled: false },
                      {
                        method: "patch",
                        encType: "application/json",
                      }
                    )
                  }
                >
                  Disable
                </Button>
              )}
              {!user.enabled && (
                <ConfirmerModal
                  title="Remove user"
                  body="Are you sure you want to remove user?"
                  isSubmitting={isSubmitting}
                  onConfirm={() =>
                    onSubmit(
                      {
                        userId: user.userId,
                      },
                      {
                        action: paths.DASHBOARD_ADMIN_USERS,
                        method: "delete",
                        encType: "application/json",
                      }
                    )
                  }
                >
                  {({ open }) => (
                    <Button disabled={isSubmitting} onClick={open}>
                      Delete
                    </Button>
                  )}
                </ConfirmerModal>
              )}
            </Stack>
          )}
        </div>
      </div>
      <div className="my-5">
        <h2 className="mb-0">Details</h2>
        <hr />
        <UserForm
          isSubmitting={isSubmitting}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={(values: any) => {
            if (!_.isEqual(values, user)) {
              onSubmit(values, {
                method: "patch",
                encType: "application/json",
              });
            }
          }}
          user={user}
          submitButtonText="Save"
        >
          {({ fields, submit }) => (
            <>
              {fields}
              <div className="mt-3">{submit}</div>
            </>
          )}
        </UserForm>
      </div>
      <h2 className="mb-0">Workspaces</h2>
      <hr />
      <div className="d-flex justify-content-start mb-5">
        <Stack direction="horizontal" gap={3}>
          <Button
            variant="primary"
            onClick={() =>
              setModal({
                show: true,
                title: "Add workspace",
                method: "post",
              })
            }
          >
            Add user to workspace
          </Button>
        </Stack>
      </div>
      {user.workspaces.length === 0 && (
        <Alert variant="info">No workspaces</Alert>
      )}
      {user.workspaces.length > 0 && (
        <UserWorkspacesList
          isSubmitting={isSubmitting}
          userWorkspaces={user.workspaces}
          onEdit={(userWorkspace) =>
            setModal({
              show: true,
              title: `Edit ${userWorkspace.workspace.label}`,
              workspace: userWorkspace,
              method: "patch",
            })
          }
          onDelete={(userWorkspace) =>
            onSubmit(
              {
                entity: "user-workspace",
                userId: user.userId,
                workspaceId: userWorkspace.workspace.workspaceId,
              },
              {
                method: "delete",
                encType: "application/json",
              }
            )
          }
        />
      )}
      <UserWorkspaceModal
        onHide={() => setModal({ show: false })}
        show={modal.show}
        title={modal.title}
        isSubmitting={isSubmitting}
        onSubmit={(values) =>
          onSubmit(
            {
              entity: "user-workspace",
              userId: user.userId,
              workspaceId: values.workspaceId,
              role: values.role,
            },
            {
              method: modal.method,
              encType: "application/json",
            }
          )
        }
        userWorkspace={
          modal.workspace
            ? {
                workspaceId: modal.workspace.workspace.workspaceId,
                role: modal.workspace.role,
              }
            : undefined
        }
        workspaces={workspacesUserIsNotIn}
      />
    </>
  );
};
