import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import _ from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { User } from "../../../api";
import paths from "../../../paths";
import { Table } from "../table";

const columnHelper = createColumnHelper<User>();

interface UsersListProps {
  users: User[];
}

export const UsersList = ({ users }: UsersListProps) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor("userId", {
        header: () => "ID",
        cell: (info) => (
          <Link
            className="stretched-link"
            to={_.replace(
              paths.DASHBOARD_ADMIN_USER,
              ":userId",
              info.row.original.userId
            )}
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("name", {
        header: () => "Name",
      }),
      columnHelper.accessor(
        (row) => {
          if (row.enabled === true) {
            return "Enabled";
          }
          if (row.enabled === false) {
            return "Disabled";
          }
          return "Invited";
        },
        {
          id: "enabled",
          header: () => "Status",
          cell: (info) => (
            <>
              <FontAwesomeIcon
                className={clsx("me-2", {
                  "text-danger": info.getValue() === "Disabled",
                  "text-success": info.getValue() === "Enabled",
                  "text-warning": info.getValue() === "Invited",
                })}
                icon={faCircle}
              />
              {info.getValue()}
            </>
          ),
          meta: { filterVariant: "select" },
        }
      ),
    ],
    []
  );
  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return <Table table={table} />;
};
