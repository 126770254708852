import { isAuthenticatedValue, useAuth } from "../../../auth";
import { Nav } from "./nav.component";

interface ConnectedNavProps {
  onSelect?: () => void;
}

export const ConnectedNav = ({ onSelect }: ConnectedNavProps) => {
  const auth = useAuth();
  const isAuthed = isAuthenticatedValue(auth);
  const userId = isAuthed ? auth.userId : undefined;
  const signOut = isAuthed ? auth.signOut : undefined;
  return (
    <Nav
      isAuthed={isAuthed}
      userId={userId}
      signOut={signOut}
      onSelect={onSelect}
    />
  );
};
