import _ from "lodash";

import { AttributeId } from "../../constants";
import { AttributeOptions, DataControlsType } from "../../types";
import {
  generateCostsData,
  getGroupsFromData,
  prepareActivityData,
} from "../../utils/data";
import { generateSubscriptionBaseCosts } from "../../utils/generate-subscription-base-costs";
import { ActivityProps } from "./activity.component";
import { ActivityLoaderData } from "./activity-loader";

interface GenerateActivityPropsArgs {
  loaderData: ActivityLoaderData;
  dataControls: DataControlsType;
  attributeOptions: AttributeOptions;
}
export const generateActivityProps = ({
  loaderData: {
    activity: data,
    datetimeRange,
    workspaces,
    subscriptionPayments,
  },
  dataControls,
  attributeOptions,
}: GenerateActivityPropsArgs): ActivityProps => {
  const { intervalId, selectedAttributes } = dataControls.value;
  const activityData = prepareActivityData({
    data,
    datetimeRange,
    intervalId,
    selectedAttributes,
    workspaces,
  });
  const workspaceIds = selectedAttributes[AttributeId.Workspace]
    ? [_.get(selectedAttributes[AttributeId.Workspace], "id")]
    : _.uniq(_.map(subscriptionPayments, "workspaceId"));
  const filteredSubscriptionPayments = _.filter(
    subscriptionPayments,
    ({ workspaceId }) => _.includes(workspaceIds, workspaceId)
  );
  const subscriptionBaseCosts = generateSubscriptionBaseCosts({
    datetimeRange,
    intervalId,
    subscriptionPayments: filteredSubscriptionPayments,
  });
  const costsData = generateCostsData({
    dataControlsValue: dataControls.value,
    activity: activityData,
    subscriptionBaseCosts,
  });
  const groups = getGroupsFromData(dataControls.value.grouping, activityData);
  return {
    costsData,
    groups,
    activityData,
    attributeOptions,
    dataControls,
  };
};
