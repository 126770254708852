import { Users } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { ActionDataWithToasts } from "../../../common/hooks";

const processUsersAction = async (request: Request): Promise<ToastType[]> => {
  const toasts: ToastType[] = [];
  const isCsvImportRequest = request.headers
    .get("Content-Type")
    ?.startsWith("multipart/form-data");
  if (isCsvImportRequest) {
    await Users.importCsv(request);
  } else {
    const values = await request.json();
    if (request.method === "POST") {
      await Users.create(values);
      toasts.push(
        createToast({
          header: "Successfully added user",
          body: `User with ID ${values.userId} has been added.`,
        })
      );
    } else if (request.method === "DELETE") {
      await Users.delete(values);
      toasts.push(
        createToast({
          header: "Successfully removed user",
          body: `User with ID ${values.userId} has been removed.`,
        })
      );
    }
  }
  return toasts;
};

export const usersAction = async ({
  request,
}: {
  request: Request;
}): Promise<ActionDataWithToasts> => {
  const toasts: ToastType[] = [];
  try {
    toasts.push(...(await processUsersAction(request)));
  } catch (error) {
    if (error instanceof Error) {
      toasts.push(
        createToast({
          header: error.name,
          body: error.message,
          type: "danger",
        })
      );
    } else {
      throw error;
    }
  }
  return { toasts };
};
