import { isAuthenticatedValue, useAuth } from "../../../auth";

export const Tokens = () => {
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  return (
    <div className="container py-3">
      <h1 className="text-center">Tokens</h1>
      <h2>Access</h2>
      <pre className="text-break text-wrap border p-3 bg-light">
        {auth.tokens.accessToken}
      </pre>
      <h2>ID</h2>
      <pre className="text-break text-wrap border p-3 bg-light">
        {auth.tokens.idToken}
      </pre>
    </div>
  );
};
