import { Form, Formik } from "formik";
import _ from "lodash";
import { Button, Form as BsForm, Modal, ModalProps } from "react-bootstrap";
import * as Yup from "yup";

import { Workspace, WorkspaceUser, WorkspaceUserRole } from "../../../api";
import { FormItem } from "../../../common/components/form-item";

type ModalUserWorkspace = Pick<WorkspaceUser, "workspaceId" | "role">;

interface UserWorkspaceModalProps extends ModalProps {
  onSubmit: (values: ModalUserWorkspace) => void;
  isSubmitting: boolean;
  userWorkspace?: ModalUserWorkspace;
  workspaces: Pick<Workspace, "workspaceId" | "label">[];
  title?: string;
}

export const UserWorkspaceModal = ({
  onSubmit,
  title,
  show,
  workspaces,
  userWorkspace = {
    workspaceId: "",
    role: WorkspaceUserRole.User,
  },
  isSubmitting,
  ...modalProps
}: UserWorkspaceModalProps) => {
  const isIncludingWorkspaceId = _.isEmpty(userWorkspace.workspaceId);
  return (
    <Modal show={show} {...modalProps}>
      <Formik
        validationSchema={Yup.object({
          workspaceId: Yup.string().required("Workspace is required").strict(),
          role: Yup.string().required("Role is required"),
        })}
        onSubmit={onSubmit}
        initialValues={userWorkspace}
      >
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting || !show}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isIncludingWorkspaceId && (
                <FormItem
                  className="mb-3"
                  name="workspaceId"
                  label="Workspace"
                  as={BsForm.Select}
                >
                  <option value="">Select</option>
                  {_.map(_.sortBy(workspaces, "label"), (workspace) => (
                    <option key={workspace.workspaceId}>
                      {workspace.label}
                    </option>
                  ))}
                </FormItem>
              )}
              <FormItem
                className="mb-3"
                name="role"
                label="Role"
                as={BsForm.Select}
              >
                <option value={WorkspaceUserRole.User}>user</option>
                <option value={WorkspaceUserRole.Admin}>admin</option>
                <option value={WorkspaceUserRole.Owner}>owner</option>
              </FormItem>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ok
              </Button>
            </Modal.Footer>
          </fieldset>
        </Form>
      </Formik>
    </Modal>
  );
};
