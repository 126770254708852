import { ReactNode } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

import paths from "../../../paths";

interface AdminProps {
  children?: ReactNode;
}

export const Admin = ({ children }: AdminProps) => {
  return (
    <>
      <Nav variant="tabs" className="mb-5">
        <Nav.Item>
          <Nav.Link as={NavLink} to={paths.DASHBOARD_ADMIN_USERS}>
            Users
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={paths.DASHBOARD_ADMIN_WORKSPACES}>
            Workspaces
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to={paths.DASHBOARD_ADMIN_SPAWNER_GROUPS}>
            Spawner groups
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {children || <Outlet />}
    </>
  );
};
