import _ from "lodash";

import { Users, WorkspaceUsers } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { ActionDataWithToasts } from "../../../common/hooks";

const processUserAction = async (request: Request): Promise<ToastType[]> => {
  const toasts: ToastType[] = [];
  const values = await request.json();
  const entity = _.get(values, "entity");
  _.unset(values, "entity");
  if (entity == "user-workspace") {
    if (request.method === "POST") {
      await WorkspaceUsers.create(values);
      toasts.push(
        createToast({
          header: "Successfully created workspace user",
          body: `User with ID ${values.userId} has been added to workspace with ID ${values.workspaceId}.`,
        })
      );
    } else if (request.method === "DELETE") {
      await WorkspaceUsers.delete(values);
      toasts.push(
        createToast({
          header: "Successfully removed workspace user",
          body: `User with ID ${values.userId} has been removed from workspace with ID ${values.workspaceId}.`,
        })
      );
    } else if (request.method === "PATCH") {
      await WorkspaceUsers.update(values);
      toasts.push(
        createToast({
          header: "Successfully updated workspace user",
          body: `User with ID ${values.userId} in workspace with ID ${values.workspaceId} has been updated.`,
        })
      );
    }
  } else {
    if (request.method === "PATCH") {
      await Users.update(values);
      toasts.push(
        createToast({
          header: "Successfully updated user",
          body: `User with ID ${values.userId} has been updated.`,
        })
      );
    }
  }

  return toasts;
};

export const userAction = async ({
  request,
}: {
  request: Request;
}): Promise<ActionDataWithToasts> => {
  const toasts: ToastType[] = [];
  try {
    toasts.push(...(await processUserAction(request)));
  } catch (error) {
    if (error instanceof Error) {
      toasts.push(
        createToast({
          header: error.name,
          body: error.message,
          type: "danger",
        })
      );
    } else {
      throw error;
    }
  }
  return { toasts };
};
