import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useField,
} from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { Button, Col, Form as BsForm, Row, Stack } from "react-bootstrap";

import { User, UserProjectValue } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { defaultUser } from "./default-user";
import { userFieldsSchema } from "./user-fields-schema";

export type Output = Pick<User, "userId" | "projects" | "groups">;

interface UserFormProps {
  onSubmit: (values: Output) => void;
  isSubmitting: boolean;
  user?: Partial<User>;
  children: ({
    fields,
    submit,
  }: {
    fields: ReactNode;
    submit: ReactNode;
  }) => ReactNode;
  submitButtonText?: string;
  isSubmitEnabledWithoutChange?: boolean;
}

// @TODO: Move to own component.
const ProjectsFormItem = () => {
  const name = "projects";
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <label className="form-label">Projects</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.key`}
                      >
                        Key
                      </label>
                      <Field
                        name={`${name}.${index}.key`}
                        placeholder="Key"
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </div>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}.value`}
                      >
                        Value
                      </label>
                      <Field
                        name={`${name}.${index}.value`}
                        placeholder="Value"
                        as="select"
                        className="form-select form-select-sm"
                        aria-label="Project value"
                      >
                        <option value="">Select</option>
                        <option value={UserProjectValue.ReadOnly}>
                          Read only
                        </option>
                        <option value={UserProjectValue.ReadWrite}>
                          Read and write
                        </option>
                        <option value={UserProjectValue.True}>True</option>
                      </Field>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label="Remove project"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}.key`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                  <ErrorMessage
                    name={`${name}.${index}.value`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push({ key: "", value: "" })}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

// @TODO: Move to own component.
const GroupsFormItem = () => {
  const name = "groups";
  const meta = useField(name)[1];
  return (
    <div className="pb-3">
      <label className="form-label">Groups</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {/* eslint-disable-next-line */}
              {meta.value.map((_item: any, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}`}
                      >
                        Group
                      </label>
                      <Field
                        name={`${name}.${index}`}
                        placeholder="Group"
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label="Remove group"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push("")}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export const UserForm = ({
  onSubmit,
  user,
  isSubmitting,
  submitButtonText = "Ok",
  isSubmitEnabledWithoutChange = false,
  children,
}: UserFormProps) => {
  const isExistingUser = !_.isUndefined(user);
  const initialValues = _.defaults(
    {},
    _.mapValues(user, (value) => (_.isNull(value) ? undefined : value)),
    defaultUser
  );
  return (
    <Formik
      validationSchema={userFieldsSchema}
      onSubmit={(value: User) =>
        onSubmit(_.pick(value, ["userId", "projects", "groups"]))
      }
      initialValues={initialValues}
    >
      {({ values }) => (
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting}>
            {children({
              fields: (
                <>
                  <Row>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="userId"
                        label="ID"
                        type="text"
                        placeholder="email"
                        readOnly={isExistingUser}
                        as={BsForm.Control}
                      />
                    </Col>
                    {isExistingUser && (
                      <>
                        <Col lg="6">
                          <FormItem
                            className="mb-3"
                            name="username"
                            label="Username"
                            type="text"
                            readOnly
                            as={BsForm.Control}
                          />
                        </Col>
                        <Col lg="6">
                          <FormItem
                            className="mb-3"
                            name="name"
                            label="Name"
                            type="text"
                            readOnly
                            as={BsForm.Control}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col lg="6">
                      <ProjectsFormItem />
                    </Col>
                    <Col lg="6">
                      <GroupsFormItem />
                    </Col>
                  </Row>
                </>
              ),
              submit: (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !isSubmitEnabledWithoutChange &&
                    _.isEqual(values, initialValues)
                  }
                >
                  {submitButtonText}
                </Button>
              ),
            })}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
