import { AxiosRequestConfig } from "axios";
import _ from "lodash";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { ResourceId, TIMEZONE_API } from "../activity/constants";
import { ActivityData, DatetimeRange, Workspace } from "../activity/types";
import { fetcher } from "../auth/fetcher";
import { CONFIG } from "../config";

interface ResponseItemCostFields {
  cpuCost: number;
  gpuCost: number;
  ramCost: number;
  networkCost: number;
  loadBalancerCost: number;
  pvCost: number;
  sharedCost: number;
  externalCost: number;
}

export interface CostsResponseItem extends ResponseItemCostFields {
  team: string;
  owner: string;
  start: string;
  cpuCoreRequestAverage: number;
  cpuCoreUsageAverage: number;
  cpuCoreHours: number;
  ramByteRequestAverage: number;
  ramByteUsageAverage: number;
  ramByteHours: number;
  gpuHours: number;
}

export type CostsResponseType = CostsResponseItem[];

interface RequestArguments {
  signal: AbortSignal;
}

interface ApiCostsRequestArguments extends RequestArguments {
  datetimeRange: DatetimeRange;
}

export const activityValidationSchema = Yup.array(
  Yup.object({
    owner: Yup.string().required(),
    team: Yup.string().required(),
    start: Yup.string().required(),
    cpuCost: Yup.number().required(),
    cpuCoreRequestAverage: Yup.number().required(),
    cpuCoreUsageAverage: Yup.number().required(),
    cpuCoreHours: Yup.number().required(),
    gpuCost: Yup.number().required(),
    gpuHours: Yup.number().required(),
    networkCost: Yup.number().required(),
    loadBalancerCost: Yup.number().required(),
    pvCost: Yup.number().required(),
    ramCost: Yup.number().required(),
    ramByteRequestAverage: Yup.number().required(),
    ramByteUsageAverage: Yup.number().required(),
    ramByteHours: Yup.number().required(),
    sharedCost: Yup.number().required(),
    externalCost: Yup.number().required(),
  })
);

export const formatUser = _.flow([
  _.partialRight(_.replace, "__AT__", "@"),
  _.partialRight(_.replace, "csiro-csiro-aad_", ""),
]);

export const generateCostsRequestConfig = (
  datetimeRange: DatetimeRange,
  signal: AbortSignal
): AxiosRequestConfig => {
  const [start, end] = datetimeRange;
  return {
    url: `${_.trimEnd(CONFIG.COSTS_API_URL, "/")}/costs/raw`,
    params: { start, end },
    signal,
  };
};

export const Activity = {
  async fetch({
    datetimeRange,
    signal,
  }: ApiCostsRequestArguments): Promise<CostsResponseType> {
    const response = await fetcher(
      generateCostsRequestConfig(datetimeRange, signal)
    );
    if (response.status !== 200) {
      throw new Response(null, {
        status: 403,
        statusText: "Access to costs API denied.",
      });
    }
    activityValidationSchema.validateSync(response.data);
    return response.data;
  },
  parseFromApi(
    data: CostsResponseType,
    workspaces: Workspace[]
  ): ActivityData[] {
    const parsed: ActivityData[] = [];
    _.each(data, (item) => {
      const workspace = _.find(workspaces, { code: item.team });
      if (_.isUndefined(workspace)) {
        console.error(`Could not find workspace with code = '${item.team}'.`);
        return;
      }
      const commonFields = {
        datetime: DateTime.fromISO(item.start, {
          zone: TIMEZONE_API,
        }).toISO() as string,
        user: { id: item.owner, label: formatUser(item.owner) },
        workspace: {
          id: workspace.workspaceId,
          label: workspace.label,
        },
      };
      if (item.cpuCost > 0) {
        parsed.push({
          ...commonFields,
          resource: { id: ResourceId.Cpu, label: "CPU" },
          cost: item.cpuCost,
          requestAverage: item.cpuCoreRequestAverage,
          usageAverage: item.cpuCoreUsageAverage,
          hours: item.cpuCoreHours,
        });
      }
      if (item.ramCost > 0) {
        parsed.push({
          ...commonFields,
          resource: { id: ResourceId.Ram, label: "RAM" },
          cost: item.ramCost,
          requestAverage: item.ramByteRequestAverage,
          usageAverage: item.ramByteUsageAverage,
          hours: item.ramByteHours,
        });
      }
      if (item.gpuCost > 0) {
        parsed.push({
          ...commonFields,
          resource: { id: ResourceId.Gpu, label: "GPU" },
          cost: item.gpuCost,
          hours: item.gpuHours,
        });
      }
      const remaining: {
        key: keyof ResponseItemCostFields;
        id: string;
        label: string;
      }[] = [
        { id: ResourceId.Network, key: "networkCost", label: "Network" },
        {
          id: ResourceId.LoadBalancer,
          key: "loadBalancerCost",
          label: "Load balancer",
        },
        { id: ResourceId.Pv, key: "pvCost", label: "PV" },
        { id: ResourceId.Shared, key: "sharedCost", label: "Shared" },
        { id: ResourceId.External, key: "externalCost", label: "External" },
      ];
      _.each(remaining, ({ key, id, label }) => {
        if (item[key] > 0) {
          parsed.push({
            ...commonFields,
            resource: { id, label },
            cost: item[key],
          });
        }
      });
    });
    return _.sortBy(parsed, "datetime");
  },
};
