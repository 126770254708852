import _ from "lodash";

import { isAuthenticatedValue } from "../../../auth";
import { AuthContextValue } from "../../../auth/context";
import { extractAttributeOptions } from "../../utils/attribute-options";
import {
  aggregateActivityDataByIntervalAndAttributes,
  alignActivityDataToInterval,
  filterActivityDataByDatetimeRange,
  generateActivityDataDefaults,
  generateCostsData,
} from "../../utils/data";
import { generateSubscriptionBaseCosts } from "../../utils/generate-subscription-base-costs";
import { ReportProps } from "./report.component";
import { ReportLoaderData } from "./report-loader";

interface GenerateReportPropsArgs {
  loaderData: ReportLoaderData;
  auth: AuthContextValue;
}

export const generateReportProps = ({
  loaderData: { activity, scopes, title, subscriptionPayments },
  auth,
}: GenerateReportPropsArgs): ReportProps => {
  const data = _.mapValues(scopes, ({ datetimeRange, intervalId }) => {
    const rangeActivityData = filterActivityDataByDatetimeRange(
      activity,
      datetimeRange
    );
    return aggregateActivityDataByIntervalAndAttributes(
      alignActivityDataToInterval(
        _.concat(
          rangeActivityData,
          generateActivityDataDefaults(
            datetimeRange,
            extractAttributeOptions(rangeActivityData),
            scopes.inner.intervalId
          )
        ),
        intervalId
      )
    );
  });
  const attributeOptions = extractAttributeOptions(data.inner);

  const outerSubscriptionBaseCosts = generateSubscriptionBaseCosts({
    datetimeRange: scopes.outer.datetimeRange,
    intervalId: scopes.outer.intervalId,
    subscriptionPayments,
  });
  const outerCostData = generateCostsData({
    activity: data.outer,
    subscriptionBaseCosts: outerSubscriptionBaseCosts,
    dataControlsValue: {
      grouping: undefined,
      datetimeRange: scopes.outer.datetimeRange,
      intervalId: scopes.outer.intervalId,
      selectedAttributes: {},
    },
  });
  const innerSubscriptionBaseCosts = generateSubscriptionBaseCosts({
    datetimeRange: scopes.inner.datetimeRange,
    intervalId: scopes.inner.intervalId,
    subscriptionPayments,
  });

  const innerCostsData = generateCostsData({
    activity: data.inner,
    subscriptionBaseCosts: innerSubscriptionBaseCosts,
    dataControlsValue: {
      grouping: undefined,
      datetimeRange: scopes.inner.datetimeRange,
      intervalId: scopes.inner.intervalId,
      selectedAttributes: {},
    },
  });

  const inner = {
    activityData: data.inner,
    costsData: innerCostsData,
    datetimeRange: scopes.inner.datetimeRange,
    intervalId: scopes.inner.intervalId,
  };
  const outer = {
    activityData: data.outer,
    costsData: outerCostData,
    datetimeRange: scopes.outer.datetimeRange,
    intervalId: scopes.outer.intervalId,
  };
  const isAuthed = isAuthenticatedValue(auth);
  const user = { name: "" };
  if (isAuthed) {
    user.name = auth.userId;
  }
  return {
    inner,
    outer,
    attributeOptions,
    user,
    title,
  };
};
